.dx-texteditor.dx-editor-outlined {
    /* background-color: #ffffff; */
    box-shadow: unset!important;
    border-radius: 4px!important;
    font-size: inherit!important;
    border: 2px solid #F4F4F8 !important;
}

.dx-texteditor.dx-editor-outlined.dx-state-hover {
    border: 2px solid  rgba(75, 85, 246, 0.149) !important;
    box-shadow: unset;
}

.dx-texteditor.dx-editor-outlined.dx-state-focused {
    border: 2px solid #4b55f6 !important;
    box-shadow: unset;
}

.dx-texteditor.dx-editor-outlined.dx-state-readonly {
    border: 2px solid #F4F4F8 !important;
    box-shadow: unset;
}

.dx-texteditor.dx-editor-outlined .dx-texteditor.dx-editor-outlined {
    border-width: 0px !important;
    box-shadow: unset;
}


.dx-checkbox-icon {
    border-color: #d9d9fc;
}

.dx-calendar-cell.dx-calendar-today span {
    border-color: #4b55f6;
}

.dx-calendar-cell.dx-calendar-contoured-date span {
    box-shadow: unset;
}

.dx-calendar-cell.dx-calendar-selected-date span {
    background-color: #4b55f6;
}
.dx-calendar-cell.ds-state-hover{
    background-color: #4b55f626;
}

.dx-datebox-date .dx-dropdowneditor-icon {
    color: #4b55f6;
}

.dx-field-item-label-location-top {
    font-size: 1rem;
    color: #414141;
}

.dx-invalid.dx-texteditor.dx-show-invalid-badge .dx-texteditor-input-container::after {
    display: none;
}


/* Custom styles for checkbox */
.lias-checkbox .dx-checkbox-icon {
    margin-right: 0.5rem;    
}
.lias-checkbox.dx-checkbox-checked .dx-checkbox-icon {
    outline: 2px solid #4B55F6;
    outline-offset: 2px;    
}

.lias-checkbox.dx-checkbox-checked .dx-checkbox-icon,
.lias-checkbox.dx-checkbox-checked .dx-checkbox-text {
    font-weight: 800;
}

.lias-checkbox.dx-state-active .dx-checkbox-icon::after, 
.lias-checkbox.dx-state-focused .dx-checkbox-icon::after {
    background-color: unset;
    transform: none;
}

.lias-checkbox.dx-checkbox-checked.dx-state-active .dx-checkbox-icon::after,
.lias-checkbox.dx-checkbox-checked.dx-state-focused .dx-checkbox-icon::after,
.lias-checkbox.dx-checkbox-indeterminate.dx-state-active .dx-checkbox-icon::after,
.lias-checkbox.dx-checkbox-indeterminate.dx-state-focused .dx-checkbox-icon::after {
    background-color: unset;    
}

.dx-checkbox.dx-checkbox-checked.dx-state-disabled .dx-checkbox-icon,
.dx-checkbox.dx-checkbox-checked.dx-state-readonly .dx-checkbox-icon,
.dx-checkbox.dx-checkbox-indeterminate.dx-state-disabled .dx-checkbox-icon,
.dx-checkbox.dx-checkbox-indeterminate.dx-state-readonly .dx-checkbox-icon {
  background-color: #8A8991;
  outline: 2px solid transparent;
  outline-offset: 2px;  
}
.dx-checkbox-checked.dx-state-readonly.dx-state-focused .dx-checkbox-icon::after,
.dx-checkbox-indeterminate.dx-state-readonly.dx-state-focused .dx-checkbox-icon::after,
.dx-checkbox.dx-state-readonly.dx-state-focused .dx-checkbox-icon::after {
  background-color: #8A8991;
  transform: scale(1);
}